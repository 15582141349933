<template>
  <div>
    <b-card footer-tag="footer" footer-class="text-right">
      <b-row>
        <b-col xl="12" lg="12" md="12" sm="12" cols="12">
          <ckeditor v-model="editorData" :config="editorConfig"></ckeditor
        ></b-col>
      </b-row>

      <template #footer>
        <b-button variant="primary" class="font-weight-bold px-10">
          Save
        </b-button>
      </template>
    </b-card>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import CKEditor from 'ckeditor4-vue'
export default {
  name: 'disclaimers-terms-conditions',
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Disclaimers' }])
  },
  components: {
    ckeditor: CKEditor.component,
  },
  data() {
    return {}
  },
}
</script>
